.footer-container {
    width: 100%;
    background-color: #ECECEC;
    height: 25px;
    position: fixed;
    display: flex;
    bottom: 0;
    margin: 3px;
    font-weight: 400;
    border-radius: 4px;
    padding-top:3px;
    justify-content: center;
  }

  .footer-text{
    color:#222222;
    font-size: 12px;
    font-family: 'Poppins';
    position: sticky;
    left:550px;

  }


  